
import { SeriesInterface } from '@/services/series';
import { TRankingOutput } from '@/services/stats';
import { TeamInterface } from '@/services/teams';
import TeamLabel from '@/components/TeamLabel.vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { store } from '@/store/store';
import { encodeTeamDivision, formatDateNoYear, formatTime, noTimezoneDate } from '@/utils';
import { defineComponent } from 'vue';
import { ACTION } from '@/store/actions';
import TheScheduleTable from './TheScheduleTable.vue';

declare type TSeriesDiagram = {
    series: SeriesInterface;
    child?: TSeriesDiagram;
    homeParent?: TSeriesDiagram;
    awayParent?: TSeriesDiagram;
    x: number;
    y: number;
};

export default defineComponent({
    name: 'TheSeriesDiagram',
    data() {
        return {
            aEditing: '',
            homeTeamScore: 0,
            awayTeamScore: 0
        }
    },
    components: { TeamLabel, ConfirmAction, TheScheduleTable },
    props: {
        series: {
            type: Array as () => SeriesInterface[],
            required: true
        },
        division: {
            type: String,
            required: true
        },
        filterSetName: {
            type: String,
            required: true
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        roundsNum(): number {
            return this.series.reduce((acc, curr) => {
                return curr.round > acc? curr.round: acc
            }, 0);
        },
        rankings(): TRankingOutput {
            return { ...store.state.seasonRanking, ...store.state.seriesRanking};
        },
        height(): number {
            return Math.pow(2, this.roundsNum) * 70;
        },
        width(): number {
            return this.roundsNum * this.xInterval;
        },
        xInterval(): number {
            let width = store.state.windowSize.width;
            if (store.state.windowSize.width > 1200) width = width * 0.7; // keep in sync with app.vue's page body
            width = Math.max(125, Math.floor((width - 10)/ this.roundsNum));

            return width;
        },
        hasRoundRobinMatchup(): boolean {
            return this.series.find(s => s.round === 0) !== undefined;
        },
        seriesMatchup(): TSeriesDiagram[] {
            const seriesPerRound = [] as SeriesInterface[][];
            this.series.forEach(s => {
                if(s.round === 0) return; // we deal with round 0 differently
                const round = s.round - 1; //computer index;
                seriesPerRound[round] === undefined?
                    seriesPerRound[round] = [s]:
                    seriesPerRound[round].push(s);
            });

            const rounds = seriesPerRound.reverse();
            const rix = 0;
            const r0 = rounds[rix];
            if(!r0) return [];

            const acc = [] as TSeriesDiagram[];
            const yInterval = this.height / Math.pow(2, rix + 1);
            const xInterval = this.xInterval;
            const x = xInterval * (rounds.length - 1 - rix);
            r0.forEach((s0, six) => {
                const y = yInterval * (six + 1);

                const homeTeam = this.homeTeam(s0);
                const awayTeam = this.awayTeam(s0);

                s0.homeTeam = homeTeam?.name;
                s0.awayTeam = awayTeam?.name;
                const newSeries = {series: s0, x, y};
                acc.push(newSeries);

                //get the parent matchups
                this.treeDFS(rix + 1, newSeries, rounds, acc);
            });

            return acc;
        },
        partialDivRankingProvisional(): {[division: string]: boolean} {
            const seasonProvisional = store.getters.seasonRankingProvisional;
            const seriesProvisional = store.getters.seriesRankingProvisional;
            const rankingMap: {[division: string]: boolean} = {};
            Object.values(store.state.series).forEach(schedule => {
                schedule.forEach(m => {
                    const div = [m.homeDivision, m.awayDivision];
                    rankingMap[div[0]] = !!(seriesProvisional[encodeTeamDivision({division: div[0], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[0], pool: ''})]);
                    rankingMap[div[1]] = !!(seriesProvisional[encodeTeamDivision({division: div[1], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[1], pool: ''})]);
                })
            })
            return rankingMap;
        },
        teamInfo(): {[teamName: string]: TeamInterface} {
            const teamMap: {[teamName: string]: TeamInterface} = {};
            store.state.teams.forEach(t => {
                teamMap[t.name] = t
            });
            return teamMap;
        },
    },
    methods: {
        timeStr(date: string): string {
            return formatTime(date);
        },
        dateStr(date: string): string {
            return formatDateNoYear(date);
        },
        toggleDiagram(){
            this.$emit('toggle', !this.collapsed);
        },
        teamFromDivision(div: string, pool: string, rank: number): TeamInterface | undefined {
            return store.getters.teamFromDivisionDef(div, pool, rank);
        },
        teamInDivision(div: string, pool: string): number {
            return store.getters.teamsInDivision(div, pool);
        },
        isBeforeDate(dateTarget: Date, dateStr: string | undefined): boolean {
            if(!dateStr) return false;
            else return (noTimezoneDate(new Date(dateStr)).getTime()) > dateTarget.getTime();
        },
        canEditResults(series: SeriesInterface): boolean {
            if(this.isEditing(series)) return false;
            if(!series.homeTeam || !series.awayTeam) return false;
            if(store.getters.isAdmin) return true
            if(series.result && !series.pendingApproval) return false;
            if(this.isBeforeDate(new Date(), series.date)) return false;
            return store.getters.canUpdateTeam(series.homeTeam) || store.getters.canUpdateTeam(series.awayTeam);
        },
        canApproveMatchResult(match: SeriesInterface): boolean {
            return match.pendingApproval !== undefined && store.getters.isTeamCaptain(match.pendingApproval?.confirmingTeam ?? '');
        },
        approveMatch(id: string){
            store.dispatch(ACTION.APPROVE_SERIES_SCORES, id);
        },
        awaitingApproval(match: SeriesInterface): boolean {
            return match.pendingApproval !== undefined;
        },
        edit(series: SeriesInterface): void {
            this.aEditing = series._id;
            this.homeTeamScore = series.result?.homeTeam ?? 0;
            this.awayTeamScore = series.result?.awayTeam ?? 0;
        },
        isEditing(series: SeriesInterface): boolean {
            return this.aEditing === series._id;
        },
        cancelEdit() {
            this.aEditing = '';
        },
        update(series: SeriesInterface): void {
            this.aEditing = '';
            if(!series.homeTeam || !series.awayTeam) return;
            
            if(store.getters.isAdmin) {
                store.dispatch(ACTION.EDIT_SERIES, {...series, ...{
                    result: {
                        homeTeam: this.homeTeamScore,
                        awayTeam: this.awayTeamScore
                    }
                }});
            }
            else {
                store.dispatch(ACTION.EDIT_SERIES_SCORES, {
                    _id: series._id,
                    homeTeam: series.homeTeam,
                    awayTeam: series.awayTeam,
                    result: {
                        homeTeam: this.homeTeamScore,
                        awayTeam: this.awayTeamScore
                    }
                });
            }
        },
        altTeam(division: string, pool: string, rank: number): string {
            let rankString = '';
            if(rank === 0) return ''; // special deal for 0 rank
            let addNumber = '#';
            if(this.teamInDivision(division, pool) === 2) {
                rankString = rank === 1? 
                    'Gagnant': rank === 2? 
                        'Perdant': rank.toString();
                addNumber = '';
            }
            else rankString = rank.toString();
            return `${addNumber}${rankString} ${pool === ''? division: pool}`;
        },
        homeTeam(match: SeriesInterface): TeamInterface|undefined {
            if(match.homeTeam) return this.teamInfo[match.homeTeam];
            else if(match.homeDivision
                && match.homeRank !== undefined
                && match.homePool !== undefined
                // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.homeDivision]
            ) {
                return this.teamFromDivision(match.homeDivision, match.homePool, match.homeRank);
            }
            else return undefined;
        },
        awayTeam(match: SeriesInterface): TeamInterface|undefined {
            if(match.awayTeam) return this.teamInfo[match.awayTeam];
            else if(match.awayDivision
                && match.awayRank !== undefined
                && match.awayPool !== undefined
                 // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.awayDivision]
            ) {
                return this.teamFromDivision(match.awayDivision, match.awayPool, match.awayRank);
            }
            else return undefined;
        },
        verticalDividerHeight(obj: TSeriesDiagram): number {
            if (!obj.child || obj.y > obj.child.y) return 0;
            return Math.abs(obj.y - obj.child.y) * 2;
        },
        verticalDividerTop(obj: TSeriesDiagram): string {
            if (!obj.child) return '0px';
            return 'calc(2em - 3px)';
        },
        treeDFS (
            rix: number, child: TSeriesDiagram,
            rounds: SeriesInterface[][],
            acc: TSeriesDiagram[]
        ) {
            //get the parent matchups
            const r1 = rounds[rix];
            if (!r1) return;

            const yInterval = this.height / Math.pow(2, rix + 1);
            const xInterval = this.xInterval;
            const x = xInterval * (rounds.length - 1 - rix);
            r1.forEach(s => {
                if(s.pool === child.series.homePool || s.pool === child.series.awayPool) {
                    const y = s.pool === child.series.homePool? child.y - yInterval: child.y + yInterval;

                    s.homeTeam = this.homeTeam(s)?.name;
                    s.awayTeam = this.awayTeam(s)?.name;
                    const newSeries = {series: s, child, x, y};
                    acc.push(newSeries);
                    s.pool === child.series.awayPool?
                        child.awayParent = newSeries:
                        child.homeParent = newSeries;

                    this.treeDFS(rix + 1, newSeries, rounds, acc);
                }
            });

            if(!child.awayParent || !child.homeParent && rix < rounds.length){
                const y = !child.homeParent? child.y - yInterval: child.y + yInterval;

                const newSeries = {
                    series: {
                       _id: '__virtualSeries__',
                       division: child.series.division,
                       pool: '',
                       homeTeam: !child.homeParent?
                           this.homeTeam(child.series)?.name: undefined,
                       awayTeam: !child.awayParent?
                           this.awayTeam(child.series)?.name: undefined,
                       homeDivision: !child.homeParent? child.series.homeDivision: '',
                       awayDivision: !child.awayParent? child.series.awayDivision: '',
                       homePool: !child.homeParent? child.series.homePool: '',
                       awayPool: !child.awayParent? child.series.awayPool: '',
                       homeRank: !child.homeParent? child.series.homeRank: 0,
                       awayRank: !child.awayParent? child.series.awayRank: 0,
                       round: rix + 1
                   },
                   child,
                   x, y
                };
                acc.push(newSeries);

                !child.awayParent?
                    child.awayParent = newSeries:
                    child.homeParent = newSeries;

                this.treeDFS(rix + 1, newSeries, rounds, acc);
            }

        }
    }
});
