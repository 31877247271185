
import { defineComponent } from 'vue';
import TheSeriesDiagram from '@/components/TheSeriesDiagram.vue';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { SeriesInterface } from '@/services/series';
import { STATE } from '@/constants';
import { MUTATION } from '@/store/mutations';
import { formatDate, timezoneDate, todayEnd } from '@/utils';
import router from '@/router/router';
import CSS from 'csstype';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'Series',
    components: {
        TheSeriesDiagram
    },
    data() {
        return {
            filterSetPrefix: 'series',
            collapsedMap: {} as {[divName: string]: boolean},
            init: false
        }
    },
    computed: {
        seriesLoading(): boolean {
            return store.state.seriesRequestStatus.status === STATE.LOADING
        },
        favorites(): TeamInterface[] {
            const favs = store.getters.teamsICaptain;
            if(store.getters.teamsIFavorite.length) {
                favs.unshift(store.getters.teamsIFavorite[0]);
            }
            return favs;
        },
        divisions(): string[] {
            return Object.keys(store.state.series);
        },
        sortedDivisions(): string[] {
            // sort divisions by favorites
            return Object.keys(store.state.series).sort((a, b) => {
                const favA = this.favoriteDiv(a)? 1: 0;
                const favB = this.favoriteDiv(b)? 1: 0;
                return favB - favA === 0? a.localeCompare(b, 'fr'): favB - favA;
            });
        },
        diagramCtnrStyle(): CSS.Properties {
            return {} as CSS.Properties;
            //return {'max-height': `calc(100% - ${this.divisions.length * 20}px)`} as CSS.Properties
        }
    },
    methods: {
        favoriteDiv(div: string): boolean {
            const favs = this.favorites;
            return favs.some(team => {
                return (
                    team.division === div ||
                    store.state.series[div].find(m => {
                        return m.homeDivision === team.division || m.awayDivision === team.division
                    }) !== undefined
                )
            });
        },
        setDivisionCollapsed(div: string, collapsed: boolean) {
            this.collapsedMap[div] = collapsed
        },
        seriesFromName(name: string): SeriesInterface[] {
            return store.state.series[name];
        },
        filterSet(division: string): string {
            return `__${this.filterSetPrefix}_${division}__`;
        }
    },
    watch: {
        $route(to) {
            if (to.path === '/series') {
                this.divisions.forEach(d => {
                    const filterSetName = this.filterSet(d);
                    store.commit(MUTATION.RESET_SCHEDULE_FILTERS, filterSetName);
                    store.commit(MUTATION.SET_ROUND_FILTER, {filterSet: filterSetName, rounds: [0]});
                    store.commit(MUTATION.SET_DIVISION_FILTERS, {filterSet: filterSetName, divisions: [d]});
                    this.collapsedMap[d] = !this.favoriteDiv(d);
                });
                if(Object.values(this.collapsedMap).every(isCollapse => isCollapse)) {
                    if(this.sortedDivisions[0]) this.collapsedMap[this.sortedDivisions[0]] = false;
                }
            }
        },
        divisions(newDivs: string[], oldDivs: string[]) {
            const same = newDivs.every((d, ix) => oldDivs[ix] === d);
            this.divisions.forEach(d => {
                const filterSetName = this.filterSet(d);
                store.commit(MUTATION.RESET_SCHEDULE_FILTERS, filterSetName);
                store.commit(MUTATION.SET_ROUND_FILTER, {filterSet: filterSetName, rounds: [0]});
                store.commit(MUTATION.SET_DIVISION_FILTERS, {filterSet: filterSetName, divisions: [d]});
                this.collapsedMap[d] = same? this.collapsedMap[d]: !this.favoriteDiv(d);
            });
            if(Object.values(this.collapsedMap).every(isCollapse => isCollapse)) {
                if(this.sortedDivisions[0]) this.collapsedMap[this.sortedDivisions[0]] = false;
            }
        }
    },
    mounted() {
        if(store.state.fieldsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS);
        }
    },
    created() {
        if (this.$route.hash === "") {
            const dateStr = formatDate(timezoneDate(todayEnd()).toISOString());
            router.replace(
                `${this.$route.fullPath}#${dateStr}`
            );
        }
    }
});
